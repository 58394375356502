import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_login_form = _resolveComponent("login-form")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { class: "ion-margin-top" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_back_button, { "default-href": "/" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_login_form)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}