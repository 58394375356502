
// components
import { IonPage, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon, } from '@ionic/vue';
import LoginForm from '@/components/auth/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: { IonPage, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                LoginForm, },
}
